// Question

$(function() {
  'use strict';

  if($('.js-question').length > 0) {
    var hash = window.location.hash;

    if(hash != "" && $(hash).length > 0) {
      var $question = $(hash),
          $details  = $question.find('details'),
          offset    = $question.offset().top - 120;

      $details.attr('open', '');
      $('html, body').animate({
        scrollTop: offset
      }, 500);
    }
  }

});
