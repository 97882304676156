// Carousel

$(function() {
  'use strict';

  $('.js-carousel').slick({
    infinite: false,
    prevArrow: $('.js-carousel-prev'),
    nextArrow: $('.js-carousel-next'),
    slidesToShow: 1,
    slidesToScroll: 1
  });

});
