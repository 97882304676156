// Header

$(function() {
  'use strict';

  var $header = $('.js-header');

  function setHeader() {
    
    if($win.scrollTop() > 0) {
      $body.addClass('is-scrolled');
    }
    else {
      $body.removeClass('is-scrolled');
    }
  }

  $win.on('scroll', function() {
    setHeader();
  });

  setHeader();

});
