// Finder

$(function() {
  'use strict';

  var $finder = $('.js-finder'),
      $toggle = $('.js-finder-toggle');

  $toggle.on('click', function(event) {
    event.preventDefault();
    $body.toggleClass('finder-opened');
  });

});
