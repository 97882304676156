// Search

$(function() {
  'use strict';


  $('.js-search').each(function() {

    // Variables
    var $search     = $(this),
        $input      = $search.find('.js-search-input'),
        $insee      = $search.find('.js-search-insee'),
        $dropdown   = $search.find('.js-search-dropdown'),
        $items      = $search.find('.js-search-items'),
        $result     = $search.find('.js-search-result'),
        api_url     = $input.data('autocomplete-url'),
        html_prefix = '<div class="c-search__item">',
        html_suffix = '</div>',
        ajax_delay = 300,
        ajax_timeout  = null;


    // Actions
    $input.bind('input', function() {
      var search = $input.val();
      clearTimeout(ajax_timeout);

      if(search != '') {
        ajax_timeout = setTimeout(function() {
          $.ajax({
            dataType: "json",
            url: api_url,
            data: 's=' + $input.val(),
            success: function (data, state) {
              if (state == 'success') {
                if (data.success) {
                  var items = data.cities;
                  var html = '';

                  if (items.length > 0) {
                    $.each(items, function (index, item) {

                      // Set Insee for first one
                      if (index === 0) {
                        $insee.val(item.insee);
                      }

                      html += html_prefix;
                      html += '<div class="c-search__result js-search-result" data-insee="' + item.insee + '">';
                      html += item.zipcode + ' - ' + item.name;
                      html += '</div>';
                      html += html_suffix;
                    });

                    $items.html(html);
                    $dropdown.show();
                  } else {
                    $dropdown.hide();
                    $insee.val('');
                  }
                } else {
                  $dropdown.hide();
                  $insee.val('');
                }
              }
            }
          });
        }, ajax_delay);
      }
      else {
        $dropdown.hide();
        $insee.val('');
      }
    });

    // Prevent Form Direct Submission
    $search.on('submit', function(e) {
      // Insee ?
      if ($insee.val()) {
        return true;
      }

      // Nope
      e.preventDefault();
    });


    $doc.on('click', '.js-search-result', function() {
      var code = $(this).data('insee');

      $insee.val(code);
      $search.submit();
    });

  });

});
