// Menu

$(function() {
  'use strict';

  var $toggle     = $('.js-toggle-navigation'),
      $navigation = $('.js-navigation'),
      $items      = $('.js-navigation-item'),
      $link       = $('.js-navigation-link'),
      touch       = $html.hasClass('touchevents') ? true : false;

  $toggle.on('click', function() {
    $body.toggleClass('navigation-opened');
  });

  $link.on('click', function(event) {
    var $this = $(this),
        $item = $this.parents('.js-navigation-item'),
        $sub  = $item.find('.js-navigation-sub');

    if($sub.length > 0 && touch) {
      event.preventDefault();

      $items.not($item).removeClass('is-active');

      $item.toggleClass('is-active');
    }
  });

});
