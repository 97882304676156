// Background

$(function() {
  'use strict';

  if($('.js-background').length > 0) {
    var $background = $('.js-background'),
        count       = $background.data('count');

    if(count > 1) {
      var pictures    = $background.data('pictures').split(" "),
          picture     = pictures[Math.floor(Math.random()*pictures.length)];

      $background.css('background-image', 'url("' + picture + '")');
    }
  }

});
