/**
 * _browser.js
 * Display a message to non supported browser.
 *
 * If needed you can update the markup of the message
 * and/or the body of browserIsCompatible() function to adjust the test based on which browsers you want to support.
 */

$(function() {
  'use strict';

  var html = '<div class="s-browser" id="browser">\
                <div class="s-browser__container">\
                  <p><strong>Votre navigateur est désuet !</strong></p>\
                  <p>Mettez à jour votre navigateur pour afficher correctement ce site Web.</p>\
                  <p><a href="http://outdatedbrowser.com/fr">Mettre à jour maintenant</a></p>\
                  <p><button id="close-browser">Fermer</button></p>\
                </div>\
              </p>';

  function browserIsCompatible(){
    // By default we treat all browsers has compatible
    var isCompatible = true;

    /*
     * No IE versions supports @supports{} and if we rely on caniuse stats this feature is pretty well supported :
     * https://caniuse.com/#search=supports
     * So this must cover our comptaibility expectations (usually last 2 versions)
     */
    var cssSupports = 'CSS' in window && 'supports' in window.CSS || 'supportsCSS' in window;
    // Target IE11 only : https://stackoverflow.com/questions/21825157/internet-explorer-11-detection
    var isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

    // If doesn't support @supports{} and is not IE11
    if(!cssSupports && !isIE11){
      isCompatible = false;
    }

    return isCompatible;
  }

  // When DOM is ready, if browser is not supported then we display the disclaimer message.
  $(document).ready(function(){
    if(!browserIsCompatible()){
      $('body').prepend(html);

      $('#close-browser').on('click', function() {
        $('#browser').remove();
      });
    }
  });

});
