

$(function() {
  'use strict';


  $('.js-coordonnees').each(function() {


    $(this).click(function(event){
      event.preventDefault();

      if($(this).attr("data-number")) {
        var number  = $(this).attr("data-number");
        $(this).replaceWith("<a href='tel:"+ number.replaceAll(' ', '')  + "' class='c-button c-button--link c-record__link'>" + number.replaceAll(' ', '') + "</<a>");
      } else {
        var email = $(this).attr("href").replaceAll('mailto:', '');
        $(this).replaceWith("<a href='mailto:"+ email  + "' class='c-button c-button--lowercase c-button--link c-record__link'>" + email + "</<a>");
      }

    });

  });
});
